/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $(document).ready(function() {
          if(window.location.hash) {
            $("html, body").animate({ scrollTop: $(window.location.hash).closest('.row').offset().top - 60 }, 1000);
          }
        });

        // Select all links with hashes
      $('a[href*="#"]')
        // Remove links that don't actually link to anything
        .not('[href="#"]')
        .not('[href="#0"]')
        .click(function(event) {
          // On-page links
          if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
            // Figure out element to scroll to
            var target = $(this.hash).closest('.row');
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            // Does a scroll target exist?
            if (target.length) {
              // Only prevent default if animation is actually gonna happen
              event.preventDefault();
              $('html, body').animate({
                scrollTop: target.offset().top - 60
              }, 1000, function() {
                // Callback after animation
                // Must change focus!
                var $target = $(target).closest('.row');
                $target.focus();
                if ($target.is(":focus")) { // Checking if the target was focused
                  return false;
                } else {
                  $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                  $target.focus(); // Set focus again
                }
              });
            }
          }
        });

        if ($('body').hasClass('news')) {
          for (i = 6; i < $('.col-md-4').length; i++) {
            $('.col-md-4:nth-of-type(' + i + ')').hide();
          }
          if ($('.col-md.4').length > 6) {
            $('.loadmore').show();
          }
          $('.loadmore').click(function() {
            $(this).hide();
            $('.col-md-4').fadeIn();
          });
        }

        if ($(window).innerWidth() < 991) {
          $('.menu-item').on('click', function() {
            if ($(this).hasClass('dropdown')) {
              $(this).toggleClass('upside');
              $(this).find('.dropdown-menu').slideToggle();
            }
          });
        }

        $('.slider-for')
          .on('init', function() {
            if ($('.slick-slide').hasClass('slick-current')) {
              var sliderText = $('.slick-slide.slick-current').find('.textFloater');
              $('.displayText').html(sliderText[0].innerHTML);
            }
          })
          .on('afterChange', function(event, slick, currentSlide, nextSlide) {
            if ($('.slick-slide').hasClass('slick-current')) {
              var sliderText = $('.slick-slide.slick-current').find('.textFloater');
              $('.displayText').html(sliderText[0].innerHTML);
            }
          });

        $('.introSlider').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          fade: false,
          infinite: true,
          waitForAnimate: true,
          dots: false,
          speed: 500,
          autoplay: true,
          autoplaySpeed: 4000,
          prevArrow:"<button type='button' class='slick-prev pull-left'><i class='ion ion-chevron-left' aria-hidden='true'></i></button>",
          nextArrow:"<button type='button' class='slick-next pull-right'><i class='ion ion-chevron-right' aria-hidden='true'></i></button>"
        });

        $('.slider-for').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          fade: false,
          infinite: true,
          waitForAnimate: true,
          dots: false,
          speed: 300,
          asNavFor: '.slider-nav',
          prevArrow:"<button type='button' class='slick-prev pull-left'><i class='ion ion-chevron-left' aria-hidden='true'></i></button>",
          nextArrow:"<button type='button' class='slick-next pull-right'><i class='ion ion-chevron-right' aria-hidden='true'></i></button>"
        });
        
        $('.slider-nav').slick({
          slidesToShow: 5,
          slidesToScroll: 1,
          asNavFor: '.slider-for',
          centerPadding: '0px',
          centerMode: true,
          draggable: false,
          swipeToSlide: false,
          touchMove: false,
          waitForAnimate: true,
          speed: 400,
          focusOnSelect: false,
          infinite: true,
          dots: false,
          arrows: false
        });

        window.onload = function() {
          $('.navbar-overflow').responsiveMenu({
              nav_class: 'navbar-overflow',
              menu_class: 'navbar-nav',
              dropdown_item_label: variables.dropdown_label,
              dropdown_item_classes: 'menu-item menu-item-has-children dropdown temporary-dropdown',
              dropdown_item_link_classes: 'nav-link dropdown-toggle',
              dropdown_item_temp_class: 'temporary-dropdown',
              dropdown_item_specific_class: 'dropdown',
              dropdown_item_parent_class: 'menu-item-has-children',
              dropdown_menu: 'dropdown-menu',
              dropdown_submenu: 'dropdown-submenu', 
              breakpoint: 991
          });
        };

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
